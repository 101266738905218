<template>
  <div class="home">
    <Header />
    <HeroMercados :data="bannermercado" />

    <div class="yellow flex-row d-flex justify-content-center py-4 py-lg-5">
      <div class=" d-sm-flex  container px-md-0 row">
        <div class="col-lg-10 d-flex align-items-center">

          <h1 class="titlebold text-left ">
            Pelo quarto ano consecutivo, a Sotreq obteve a certificação do Great Place to Work (GPTW) como uma das
            melhores empresas para se trabalhar.
          </h1>
        </div>
        <div class="col-lg d-flex justify-content-center align-items-center">

          <img style="height: 289px" src="../../assets/gptw2025.png" class="img-fluid">
        </div>
      </div>

    </div>
<div class="sticky-top">





    <div class="graybar">
      <div class="container px-md-0 ">

          <div class=" d-flex flex-row justify-content-between h-100 align-items-center">

        <div class="d-none d-md-none d-lg-block d-xl-block">

        <div class="d-none d-md-block d-lg-none h-100">
          <div class="d-flex flex-row justify-content-between h-100 align-items-center">

            <h3 class="text-white py-0 my-0">VEM SER SOTREQ!</h3>
          </div>
        </div>
            <a class="linkdpaced mr-3" href="/#trainee">Programa Trainee</a>
            <a class="linkdpaced mr-3" href="/#estagio">Programa de Estágio</a>
            <a class="linkdpaced mr-3" href="/#aprendiz">Programa Jovem Aprendiz</a>
            <a class="linkdpaced" href="/#mecanicos">Escola de Formação de Mecânicos</a>
          </div>
        <div class="py-3 h-100 align-items-center">
          <a  type="button" class="btnsend btn btn-secondary" href="https://jobs.jobconvo.com/pt-br/careers/Sotreq/f6adee26-687e-4320-89a9-6ef13602f81d/" target="_blank">
            Conheça nossas vagas
          </a>
        </div>
                </div>

      </div>
</div>
</div>

    <div class="container px-md-0 text-left">
      <div>
        <br>
      </div>
      <div>
        <br>
      </div>
      <div>
        <strong class="boldstrong">Trabalho com propósito. É disso que se trata.</strong>
      </div>
      <div><br></div>
      <div class="normaltext">Se você é do tipo que gosta de botar a mão na massa e fazer acontecer, não perca a oportunidade de participar dos processos seletivos da Sotreq.
Queremos ser, para você, uma empresa extraordinária para se trabalhar.
      </div>
    </div>
    <div class="d-none d-md-none d-lg-block d-xl-block">
      <div class=" container px-md-0 my-2 pb-4">
        <img src="../../assets/nosso-jeito.png" alt="Sotreq trabalhe conosco-nosso jeito" class="img-fluid">
      </div>
    </div>
    <div class="d-none d-sm-none d-md-block d-lg-none">
      <div class=" px-0 my-2">
        <img src="../../assets/nossojeitotablet.png" alt="Sotreq trabalhe conosco-nosso jeito">
      </div>
    </div>
    <div class="d-block d-sm-block d-md-none">
      <div class=" px-0 my-2">
        <img src="../../assets/nossojeitocelular.png" alt="Sotreq trabalhe conosco-nosso jeito">
      </div>
    </div>
    <div class="yellow flex-row d-flex justify-content-center">
      <p class="titleonyellow my-2">Programas e Projetos</p>
    </div>
    <div class="d-none d-sm-none d-md-block d-lg-block d-xl-block">
      <div class=" container px-md-0 text-left py-2">
        <div class="normaltext mt-2">O Respeito nas Relações está nos nossos valores. Por isso, trabalhamos ativamente na
          promoção
          da diversidade em nossa empresa e no compromisso de uma atuação responsável
          e consciente nas comunidades onde estamos inseridos. Esse é o nosso jeito de valorizar clientes e colaboradores.
        </div>
      </div>
    </div>
    <div class=" container px-md-0">
      <div class="row px-0 mx-0">
        <div class="col px-0 mx-0">
          <div class="card mb-3 border-0">
            <div class="row px-0 mx-0 d-flex flex-md-row align-items-center">

              <div class="col-md-7 px-0 mx-0 d-flex flex-column justify-content-center py-1">
                <div class="text-left card-body px-0 mx-0">
                  <div class="d-flex justify-content-between flex-column">
                    <div>
                      <h2 class="fw-700">Programa Pertencer</h2>
                      <div class="bar mb-3"></div>
                      <h4 class="mb-4 fw-700"> Diversidade, equidade e inclusão</h4>
                      <p class="mb-3"> Para nós, pluralidade é uma prática que se baseia em um dos valores corporativos
                        mais importantes:
                        respeito nas relações. Há mais de 10 anos, a Sotreq mantém o Programa Pertencer, que vem ampliando
                        as frentes de trabalho
                        com ações voltadas para o reconhecimento e
                        valorização das diferenças em nossas relações com colaboradores, clientes, terceiros, parceiros de
                        negócios e comunidade.</p>
                      <img src="../../assets/pertencer.png" class="img-fluid" />
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-4 mx-0 px-0 d-flex ">
                <img src="../../assets/rodapessoas.png" class="img-fluid" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="yellow">
      <div class=" container px-md-0">
        <div class="row px-0 mx-0 yellow">
          <div class="col px-0 mx-0">
            <div class="card mb-3 border-0">
              <div class="row px-0 mx-0 d-flex flex-md-row align-items-center yellow pt-2">

                <div class="col-lg-6 px-0 mx-0 d-flex flex-column justify-content-center py-1">
                  <div class="text-left card-body px-0 mx-0">
                    <div class="d-flex justify-content-between flex-column">
                      <div>
                        <h2 class="fw-700">Instituto Social Sotreq</h2>
                        <div class="black-bar mb-4"></div>
                        <p class="mb-5"> O investimento social ganhou mais força no Grupo Sotreq com a criação
                          de um instituto empresarial! Fundada em 9 de junho de 2005, a Organização da Sociedade Civil
                          denominada
                          INSTITUTO SOCIAL SOTREQ, igualmente conhecida como iSSO, é uma pessoa jurídica de direito
                          privado sem fins econômicos,
                          que investe em projetos sociais nas áreas de Educação Profissional, Inclusão Digital,
                          Enriquecimento Educacional,
                          Atuação Socioambiental.</p>
                        <div class="d-flex justify-content-lg-start justify-content-center">

                          <a type="button" class="btn sotreqlinkbtn mt-3" href="https://isso.org.br" target="_blank">
                            Conheça o instituto
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6 mx-0 px-0 d-flex ">
                  <img src="../../assets/deverdecasa.png" class="img-fluid" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="text-center">
          <h1 class="titlecaps">iSSO EM NÚMEROS COMPROMISSO COM O INVESTIMENTO SOCIAL </h1>
        </div>
        <div class="d-flex flex-column flex-lg-row pb-4">
          <div class="d-flex flex-column pr-3 w-25" style="border-right: 2px solid black;">
            <h1 class="boxtitle">+250 mil</h1>
            <h4 class="boxsubtitle">PESSOAS BENEFICIADAS</h4>
            <p class="boxtext">Nas parcerias com instituições de ensino e OSCs</p>
          </div>
          <div class="d-flex flex-column px-3 w-25" style="border-right: 2px solid black;">
            <h1 class="boxtitle">+18 anos</h1>
            <h4 class="boxsubtitle">DE ATUAÇÃO</h4>
            <p class="boxtext">Equipe, parceiros, investidores e executores unidos pelos propósito de valorização do
              potencial humano e compromisso com a sustentabilidade</p>
          </div>
          <div class="d-flex flex-column px-3 w-25" style="border-right: 2px solid black;">
            <h1 class="boxtitle">+130</h1>
            <h4 class="boxsubtitle">PROJETOS APOIADOS</h4>
            <p class="boxtext">Investimento social em projetos de capacitação profissional e socioambiental</p>
          </div>
          <div class="d-flex flex-column pl-3 w-25 " style="">
            <h1 class="boxtitle">+1.200</h1>
            <h4 class="boxsubtitle">VOLUNTARIADOS</h4>
            <p class="boxtext">Anualmente em conexão com os projetos sociais</p>
          </div>
        </div>
      </div>
    </div>



    <div class=" container px-md-0" id="trainee">
      <div>
        <div class="desatque my-4">
          <div class="row px-0 mx-0">
            <div class="col px-0 mx-0">
              <div class="card mb-3 border-0">
                <div class="row px-0 mx-0 d-flex flex-md-row align-items-center">
                  <div class="col-md-7 mx-0 px-0 d-flex ">
                    <img src="https://sitesotreq.manacadigital.com.br/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBcElHIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--411c56917431174a80a44708257d95e7ead197cb/trainee.jpg" class="img-fluid" />
                  </div>
                  <div class="col-md-5 d-flex flex-column justify-content-center py-1">
                    <div class="text-left card-body">
                      <div class="d-flex justify-content-between flex-column">
                        <div>
                          <h2 class="fw-700">Programa Trainee</h2>
                          <div class="bar mb-3"></div>
                          <h4 class="mb-4 fw-700"> Você, protagonista dos seus sonhos</h4>
                          <p class="mb-3"> Aqui, na Sotreq, você encontra um lugar preparado para o seu crescimento.
                            Aprender a trabalhar junto, em um time coeso, com propósitos comuns: é assim que a gente cria
                            o futuro.</p>
                        </div>
                      </div>
                      <div class="d-flex justify-content-start">

<a type="button" class="btn sotreqlinkbtn mt-3" href="https://www.vagas.com.br/vagas/v2574967/programa-trainee-2024" target="_blank">
  Quero ser um Trainee
</a>
</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="desatque my-4"  id="estagio">
          <div class="row px-0 mx-0">
            <div class="col px-0 mx-0">
              <div class="card mb-3 border-0">
                <div class="row px-0 mx-0 d-flex flex-md-row-reverse align-items-center">
                  <div class="col-md-7 mx-0 px-0 d-flex ">
                    <img src="../../assets/estagio.png" class="img-fluid" />

                  </div>
                  <div class="col-md-5 d-flex flex-column justify-content-center py-1">
                    <div class="text-left card-body">
                      <div class="d-flex justify-content-between flex-column">
                        <div>
                          <h2 class="fw-700">Programa de Estágio</h2>
                          <div class="bar mb-3"></div>
                          <h4 class="mb-4 fw-700"> Para chegar ainda mais longe</h4>
                          <p class="mb-3"> Experimentar, estagiar, crescer. No Programa de Estágio da Sotreq, o estagiário
                            tem a oportunidade de experimentar diversas áreas e segmentos, dentro de uma estrutura sólida,
                            focada em crescimento.</p>

                        </div>
                      </div>
                      <div class="d-flex justify-content-start">

                        <a type="button" class="btn sotreqlinkbtn mt-3" href="https://app.jobconvo.com/job/Mjg5NzczOA-programa-de-estagio-20243-diversas-regioes/33344c9a-5750-4c89-9990-915d07720e65/" target="_blank">
                          Seja estagiário na Sotreq
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class=" container px-md-0" id="aprendiz">
      <div class="row px-0 mx-0">
        <div class="col-md px-0 mx-0">
          <div class="card mb-3 border-0">


            <div class="col d-flex flex-column justify-content-center py-1">
              <img src="../../assets/aprendiz.png" class="img-fluid" />
              <div class="text-left card-body px-0">
                <div class="d-flex justify-content-between flex-column">
                  <div>
                    <h2 class="fw-700">Programa Jovem Aprendiz</h2>
                    <div class="bar mb-3"></div>
                    <h4 class="mb-4 fw-700">Entusiasmo e vontade de aprender</h4>
                    <p class="mb-3">Se você possui entre 14 e 24 anos, este programa é para você. Venha fazer parte de um
                      time que desenvolve suas habilidades, em um ambiente diverso, acolhedor e inspirador.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md px-0 mx-0" id="mecanicos">
          <div class="card mb-3 border-0">


            <div class="col d-flex flex-column justify-content-center py-1">
              <img src="../../assets/mecanico.png" class="img-fluid" />
              <div class="text-left card-body px-0">
                <div class="d-flex justify-content-between flex-column">
                  <div>
                    <h2 class="fw-700">Escola de Formação de Mecânico</h2>
                    <div class="bar mb-4"></div>
                    <h4 class="mb-4 fw-700">O seu futuro pode ser agora</h4>
                    <p class="mb-3">Para você que é dinâmico e está cheio de vontade de aprender, a Escola de Formação de
                      Mecânicos oferece capacitação prática e teórica com duração de cerca de três meses que forma
                      profissionais para atuar em manutenção de máquinas e equipamentos pesados.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="d-flex justify-content-center">
      <div class="bar mb-4" style="width: 310px !important;"></div>
    </div>

    <div class=" container px-md-0 text-left">
      <strong class="boldstrong">80 anos Sotreq | Você faz parte disso</strong>
      <div class="bar mt-1"></div>
      <div class="embed-responsive embed-responsive-16by9 mt-4">

<iframe class="embed-responsive-item" src="https://www.youtube.com/embed/UJkivmfPEEs?si=YVfAAhlMsKdFPwOz" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
    </div>
    </div>

    <Footer />
  </div>
</template>

<script>
// @ is an alias to /src
import Header from "@/components/Header.vue";
import HeroMercados from "@/components/Mercados/HeroCarreiras.vue";
import LineTextBlock from "@/components/shared/LineTextBlock.vue";
import AlternatingHcard from "@/components/shared/AlternatingHcard";
import IconCardLine from "@/components/shared/IconCardLine";
import CardsFiltragem from "@/components/CardsFiltragem.vue";
import CardSimple from "@/components/shared/CardSimple.vue"
import Footer from "@/components/Footer.vue";
import ApiContent from "@/services/api.js";
import gptw from "@/assets/gptw.png"

export default {
  name: "Carreiras",
  mounted() {
    this.loadContent();
  },
  components: {
    Header,
    HeroMercados,
    LineTextBlock,
    AlternatingHcard,
    CardsFiltragem,
    CardSimple,
    IconCardLine,
    Footer,
  },
  methods: {
    async loadContent() {
      const resp = await ApiContent.get("pgcarreiras.json");
      console.log(resp.data);
      this.heroTopTitulo = resp.data.heroTopTitulo;
      this.heroTopTexto = resp.data.heroTopTexto;
      this.heroTopLink = resp.data.heroTopLink;
      this.heroTopButtonText = resp.data.topherobuttontext;
      this.destaqueTitulo = resp.data.destaqueTitulo;
      this.destaqueTexto = resp.data.destaqueTexto;
      this.destaqueChamada = resp.data.destaqueChamada;
      this.textoBaseTitulo = resp.data.textoBaseTitulo;
      this.textoBaseTexto = resp.data.textoBaseTexto;
      this.heroBottomTitulo = resp.data.heroBottomTitulo;
      this.heroBottomTexto = resp.data.heroBottomTexto;
      this.heroBottomLink = resp.data.heroBottomLink;
      this.bottomherobuttontext = resp.data.bottomherobuttontext;
      this.heroBottomLink = resp.data.heroBottomLink;
      this.url = resp.data.url;
      this.topHeroImage = resp.data.topHeroImage;
      this.logo = resp.data.logo;
      this.bottomHeroImage = resp.data.bottomHeroImage;
      this.heroes = resp.data.heroes;
      this.cards = resp.data.cards;
      this.destaques = resp.data.destaques;
      this.conteudo = resp.data.content;
      this.bannermercado = {
        titulo: resp.data.heroTopTitulo,
        image: resp.data.topHeroImage,
        subtitulo: "",
      };
    },
  },
  data() {
    return {
      heroTopTitulo: [],
      heroTopTexto: [],
      heroTopLink: [],
      topherobuttontext: [],
      destaqueTitulo: [],
      destaqueTexto: [],
      destaqueChamada: [],
      textoBaseTitulo: [],
      textoBaseTexto: [],
      heroBottomTitulo: [],
      heroBottomTexto: [],
      heroBottomLink: [],
      created_at: [],
      updated_at: [],
      url: [],
      topHeroImage: [],
      logo: [],
      bottomHeroImage: [],
      cards: [],
      destaques: [],
      conteudo: [],
      heroes: [],
      bannermercado: [],
    };
  },
};
</script>
<style>
h1.font-weight-bold.display-3 {
    font-size: 56px !important;
    white-space: nowrap;
}
.fw-700 {
    font-weight: 700 !important;
}
a.btn.sotreqlinkbtn.mt-3:hover {
    color: #fff !important;
    background-color: #000 !important;
}
.black-bar {
    margin-top: -5px;
    margin-bottom: 10px;
    height: 5px;
    background-color: #000;
    width: 100px;
}
@media(max-width: 568px ){
.w-25 {
    width: 100% !important;
    min-width: 100% !important;
}
.d-flex.flex-column.pr-3.w-25, .d-flex.flex-column.px-3.w-25, .d-flex.flex-column.pl-3.w-25 {
    border: none !important;
}
h1.titlebold.text-left {
    font-size: 32px !important;
}
div.col-lg.d-flex.justify-content-center.align-items-center > img {
  min-height: 463px;
  height: 463px !important;
  margin-top: 16px;
}
div.d-flex.flex-row.justify-content-between.h-100.align-items-center {
      margin: 0% 19% 0% 24% !important;
}
.sticky-top {
  z-index: 9999;
}


 button.btn.d-flex.w-100.text-white.justify-content-between.collapsed > i.fa.fa-angle-right.tex-white {
      transform: rotate(0deg);
    transition: all 0.3s linear;
 }
 
button.btn.d-flex.w-100.text-white.justify-content-between > i.fa.fa-angle-right.tex-white {
        transform: rotate(90deg);
}

}
</style>
<style scoped>
.quadrado {
  border: 5px solid #ffcb11;
}

.yellow {
  background-color: #ffcb11;
}

.linkdpaced {
  color: #FFF;
  padding-bottom: 8px;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  transition: all 0.3s;
  border-bottom: 1px solid transparent;

}


.linkdpaced:hover {
  border-bottom: 1px solid #ffcb11;
}

.boxtitle {
  color: #252525;
   
  font-size: 56px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.boxsubtitle {
  color: #252525;
   
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.boxtext {
  color: #252525;
  text-align: center;
   
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.titlecaps {
  color: #252525;
   
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.titleonyellow {
  color: #000;
   
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.boldstrong {
  color: #000;
  leading-trim: both;
  text-edge: cap;
   
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.normaltext {
  color: #000;
   
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.graybar {
  background-color: #252525;

}

.graybar a {
  color: white;
  text-decoration: none;
}

.btnsend {
  background-color: #f7b220;
  border: #f7b220;
  color: black !important;
  padding: 10px;
  border-radius: 0.6em;
  font-size: 16px;
}

.btnsend:hover {
    background-color: #fff;
  border: #000;
  color: black !important;

  padding: 10px;
  border-radius: 0.6em;
  font-size: 16px;
}

.sotreqlinkbtn {
  background-color: transparent !important;
  border: 1px solid black !important;
  color: black !important;
  padding: 10px;
  border-radius: 0.6em;
  font-size: 16px;
}

.titlebold {
  color: #252525;
   
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.equipamento_nvo {
  background: rgb(2, 0, 36);
  background: radial-gradient(circle,
      rgba(2, 0, 36, 1) 0%,
      rgba(214, 155, 31, 1) 50%);
}

.bg-sotreq {
  background-color: rgb(255, 206, 17);
}

a {
  text-decoration: none;
}

button {
  border: 2px solid white;
  background-color: black;
  color: white;
}

button:hover {
  border: 2px solid black;
  background-color: white;
  color: black;
}




  </style>
