import Vue from 'vue'
import VueRouter from 'vue-router'
import VueGtm from 'vue-gtm'
import HomeSotreq from '../views/HomeSotreq.vue'
import Florestal from '../views/Mercados/Florestal.vue'
import AreiaEBrita from '../views/Mercados/AreiaEBrita.vue'
import Agricola from '../views/Mercados/Agricola.vue'
import Construcao from '../views/Mercados/Construcao.vue'
import Energia from '../views/Mercados/Energia.vue'
import Industrial from '../views/Mercados/Industrial.vue'
import Maritimo from '../views/Mercados/Maritimo.vue'
import Mineracao from '../views/Mercados/Mineracao.vue'
import OleoEGas from '../views/Mercados/OleoEGas.vue'
import Pavimentacao from '../views/Mercados/Pavimentacao.vue'
import NossasMaquinas from '../views/NossasMaquinas.vue'
import Motores from '../views/Motores.vue'
import Aluguel from '../views/Aluguel.vue'
import Acessorios from '../views/Acessorios.vue'
import GrupoGeradores from '../views/GrupoGeradores.vue'
import AcessorioInner from '../views/AcessorioInner.vue'
import GrupoGeradoreseMotores from '../views/GrupoGeradoreseMotores.vue'
import MotoresMaritimos from '../views/MotoresMaritimos.vue'
import MotoresIndustriais from '../views/MotoresIndustriais.vue'
import SistemaDePropulsao from '../views/SistemaDePropulsao.vue'
import Transmissoes from '../views/Transmissoes.vue'
import Bombaspoco from '../views/Produtos/Bombaspoco.vue'
import ProdutosMangueiras from '../views/Mangueiras.vue'
import Filtragem from '../views/Filtragem.vue'
import PecasGenuinasCatR from '../views/PecasGenuinasCatR.vue'
import Baterias from '../views/PecasGenuinas/Baterias.vue'
import Filtros from '../views/PecasGenuinas/Filtros.vue'
import Fluidos from '../views/PecasGenuinas/Fluidos.vue'
import PenetracaoSolo from '../views/PecasGenuinas/PenetracaoSolo.vue'
import Hidraulicos from '../views/PecasGenuinas/Hidraulicos.vue'
import MaterialRodante from '../views/PecasGenuinas/MaterialRodante.vue'
import Mangueiras from '../views/PecasGenuinas/Mangueiras.vue'
import PecasReman from '../views/PecasGenuinas/PecasReman.vue'
import PecasUsadas from '../views/PecasUsadas.vue'
import OpcoesReparo from '../views/OpcoesReparo.vue'
import Contratos from '../views/Contratos/Contratos.vue'
import SotreqLink from '../views/Contratos/SotreqLink.vue'
import SotreqLinkLite from '../views/Contratos/SotreqLink/SotreqLinkLite.vue'
import SotreqLinkClassic from '../views/Contratos/SotreqLink/SotreqLinkClassic.vue'
import SotreqLinkPlus from '../views/Contratos/SotreqLink/SotreqLinkPlus.vue'
import ConstrucaoContrato from '../views/Contratos/ConstrucaoContrato.vue'
import PetroleoGasContrato from '../views/Contratos/PetroleoGasContrato.vue'
import PmpContrato from '../views/Contratos/Contrucao/PmpContrato.vue'
import ServicePlusMarc from '../views/Contratos/Contrucao/ServicePlusMarc.vue'
import MaqExtra from '../views/Contratos/Contrucao/MaqExtra.vue'
import MineracaoContrato from '../views/Contratos/MineracaoContrato.vue'
import CVA from '../views/Contratos/Mineracao/CVA.vue'
import MARC from '../views/Contratos/Mineracao/MARC.vue'
import PBTH from '../views/Contratos/Mineracao/PBTH.vue'
import PBTW from '../views/Contratos/Mineracao/PBTW.vue'
import ServicosManutencao from '../views/ServicosManutencao.vue'
import ProgramaSOS from '../views/ProgramaSOS.vue'
import ServicosEnergia from '../views/ServicosEnergia.vue'
import ServicoCRC from '../views/ServicoCRC.vue'
import ServicoMaritimoComercial from '../views/ServicoMaritimoComercial.vue'
import ServicoOleoEGas from '../views/ServicoOleoEGas.vue'
import ServicoMaritimoLazer from '../views/ServicoMaritimoLazer.vue'
import ServicoTreinamentosTecnicos from '../views/ServicoTreinamentosTecnicos.vue'
import Aplicacoes from '../views/Tecnologia/Aplicacoes.vue'
import SotreqLinkTec from '../views/Tecnologia/Aplicacoes/SotreqLinkTec.vue'
import CATinspect from '../views/Tecnologia/Aplicacoes/CATinspect.vue'
import VisionLink from '../views/Tecnologia/Aplicacoes/VisionLink.vue'
import ProductLinkWeb from '../views/Tecnologia/Aplicacoes/ProductLinkWeb.vue'
import SOSWeb from '../views/Tecnologia/Aplicacoes/SOSWeb.vue'
import CATConnect from '../views/Tecnologia/CATConnect.vue'
import Rajant from '../views/Tecnologia/Rajant.vue'
import Sitech from '../views/Tecnologia/Sitech.vue'
import DrenagemEficiente from '../views/Tecnologia/DrenagemEficiente.vue'
import SolucoesMaritimo from '../views/Tecnologia/SolucoesMaritimo.vue'
import Financiamento from '../views/Financiamento/Financiamentos.vue'
import CATFinancial from '../views/Financiamento/CATFinancial.vue'
import FINAME from '../views/Financiamento/FINAME.vue'
import Consorcios from '../views/Financiamento/Consorcios.vue'
import Institucional from '../views/SobreNos/Institucional.vue'
import Esg from '../views/SobreNos/esg.vue'
import PoliticaSust from '../views/SobreNos/Politica.vue'
import ValorCorporativo from '../views/SobreNos/ValorCorporativo.vue'
import Conduta from '../views/SobreNos/Conduta.vue'
import AntiCorrupcao from '../views/SobreNos/AntiCorrupcao.vue'
import iSSO from '../views/SobreNos/iSSO.vue'
import Sustentabilidade from '../views/SobreNos/Sustentabilidade.vue'
import Imprensa from '../views/SobreNos/Imprensa.vue'
import Wallpapers from '../views/SobreNos/WallPapers.vue'
import PremiosCertific from '../views/SobreNos/PremiosCertific.vue'
import TreinamentosPresenciais from '../views/TreinamentosPresenciais.vue'
import TreinamentosPresenciaisInnerCategoria from '../views/TreinamentosPresenciaisInnerCategoria.vue'
import TreinamentoInfo from '../views/TreinamentoInfo.vue'
import LaboratorioSOS from '../views/LaboratorioSOS/LaboratorioSOS.vue'
import NossoLab from '../views/LaboratorioSOS/NossoLab.vue'
import OleoDisel from '../views/LaboratorioSOS/OleoDisel.vue'
import Arrefecimento from '../views/LaboratorioSOS/Arrefecimento.vue'
import OleoLubrificante from '../views/LaboratorioSOS/OleoLubrificante.vue'
import KitsMontagem from '../views/LaboratorioSOS/KitsMontagem.vue'
import ColeteAmostra from '../views/LaboratorioSOS/ColeteAmostra.vue'
import FichasManuais from '../views/LaboratorioSOS/FichasManuais.vue'
import Amostra1234 from '../views/LaboratorioSOS/Amostra1234.vue'
import CategoriaEspecific from '../views/CategoriaEspecific.vue'
import SubCategoriaEspecific from '../views/SubCategoriaEspecific.vue'
import SubcategoriaAcessorios from '../views/SubcategoriaAcessorios.vue'
import MaquinaDetalhe from '../views/MaquinaDetalhe.vue'
import AcessorioDetalhe from '../views/AcessorioDetalhe.vue'
import Filiais from '../views/filiais.vue'
import FilialDetalhe from '../views/FilialDetalhe.vue'
import TecnologiaSolucoesMineracao from '../views/Tecnologia/SolucoesMineracao.vue'
import TecnologiaSolucoesSotreqlink from '../views/Tecnologia/SolucoesSotreqlink.vue'
import FinanciamentoFiname from '../views/FinanciamentoFiname.vue'
import FaleConosco from '../views/FaleConosco.vue'
import Contato from '../views/Contato.vue'
import FaleContato from '../views/FaleContato.vue'
import FormularioEnviado from '../views/FormularioEnviado.vue'
import NewsletterEnviado from '../views/NewsletterEnviado.vue'
import AplicacoesMineracao from '../views/Mineração/Aplicacoes/Aplicacoes.vue'
import SolucoesMineracao from '../views/Mineração/Solucoes/Solucoes.vue'
import Autonomas from '../views/Mineração/Solucoes/Autonomas.vue'
import EficienciaOperacional from '../views/Mineração/Solucoes/EficienciaOperacional.vue'
import MonitoramentoCondicoes from '../views/Mineração/Solucoes/MonitoramentoCondicoes.vue'
import OperacoesRemotas from '../views/Mineração/Solucoes/OperacoesRemotas.vue'
import Seguranca from '../views/Mineração/Solucoes/Seguranca.vue'
import SustentabilidadeMineracao from '../views/Mineração/Solucoes/Sustentabilidade.vue'
import CarregamentoTransporte from '../views/Mineração/Aplicacoes/CarregamentoTransporte.vue'
import InfraestruturaSuporte from '../views/Mineração/Aplicacoes/InfraestruturaSuporte.vue'
import Perfuracao from '../views/Mineração/Aplicacoes/Perfuracao.vue'
import ProjetosEspeciais from '../views/Mineração/Aplicacoes/ProjetosEspeciais.vue'
import Subterranea from '../views/Mineração/Aplicacoes/Subterranea.vue'
import Fleet from '../views/Mineração/Paginas/Fleet.vue'
import Terrain from '../views/Mineração/Paginas/Terrain.vue'
import Fadiga from '../views/Mineração/Paginas/Fadiga.vue'
import Detect from '../views/Mineração/Paginas/Detect.vue'
import Edge from '../views/Mineração/Paginas/Edge.vue'
import Command from '../views/Mineração/Paginas/Command.vue'
import Health from '../views/Mineração/Paginas/Health.vue'
import Promocoes from '../views/promocoes.vue'
import CatAdvantage from '../views/cat-advantage.vue'
import FiltragemIndustrial from '../views/Filtragem/FiltragemIndustrial.vue'
import FiltragemMaritimo from '../views/Filtragem/FiltragemMaritimo'
import FiltragemOleoegas from '../views/Filtragem/FiltragemOleoegas'
import FiltragemMineracao from '../views/Filtragem/FiltragemMineracao'
import FiltragemEnergia from '../views/Filtragem/FiltragemEnergia'
import FiltragemEolico from '../views/Filtragem/FiltragemEolico'
import Carreiras from '../views/carreiras/Carreiras'
import PecasPneus from '../views/PecasPneus'
import Cjc from '../views/Pecas/Cjc.vue'
import Emd from '../views/Pecas/Emd.vue'
import Mak from '../views/Pecas/Mak.vue'
import Spbt from '../views/Pecas/Spbt.vue'
import ServicosOficina from '../views/Servicos/ServicosOficina.vue'
import ServicosCampo from '../views/Servicos/ServicosCampo.vue'
import Rodante from '../views/Material/Rodante.vue'
import ServicosHidraulico from '../views/Servicos/ServicosHidraulico.vue'
import TremDeForca from '../views/TremDeForca.vue'
import Tfsh from '../views/Tfsh.vue'
import Premier from '../views/Premier.vue'
import Cat360Advantage from '../views/Cat360Advantage.vue'
import Politica from '../views/Politica.vue'
import ConsumoDeCombustivel from '../views/ConsumoDeCombustivel.vue'
import TrabalheConosco from '../views/carreiras/TrabalheConosco.vue'
import Contabeis from '../views/Contabeis.vue'
import IgualdadeSalarial from '../views/IgualdadeSalarial.vue'
import Locker from '../views/Locker.vue'
import Blackfriday1 from '../views/Blackfriday/Blackfriday1.vue'
import FormularioEnviadoBlackfriday from '../views/Blackfriday/FormularioEnviadoBlackfriday.vue'
import Blackfriday2 from '../views/Blackfriday/Blackfriday2.vue'
import Blackfriday3 from '../views/Blackfriday/Blackfriday3.vue'
import FormularioPecasUsadasEnviado from '../views/FormularioPecasUsadasEnviado.vue'
import Cilindros from '../views/Cilindros.vue'
import BombasMotores from '../views/BombasMotores.vue'
import FormularioGeralEnviado from '../views/FormularioGeralEnviado.vue'
import ProjetosIncentivados  from '../views/ProjetosIncentivados.vue'
import TesteChat from '../views/TesteChat.vue'
import TecnicoAutorizado from '../views/TecnicoAutorizado.vue'
import Garantia from '../views/Garantia.vue'
import Depoimentos from '../views/Depoimentos.vue'
import PecasOnline from '../views/PecasOnline.vue'
import Sem from '../views/Produtos/Sem.vue'
import TeleEngenharia from '../views/TeleEngenharia.vue'
import MineracaoSubterranea from '../views/Tecnologia/MineracaoSubterranea.vue'
import SotreqCat from '../views/Tecnologia/SotreqCat.vue'
import SotreqResemin from '../views/Tecnologia/SotreqResemin.vue'
import SotreqRobit from '../views/Tecnologia/SotreqRobit.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '*',
    redirect: '/'
  },
  {
    path: '/',
    name: 'Home',
    component: HomeSotreq
  },
  {
    path: '/mineracao/aplicacoes',
    name: 'AplicacoesMineracao',
    component: AplicacoesMineracao
  },
  {
    path: '/mineracao/edge',
    name: 'Edge',
    component: Edge
  },
  {
    path: '/mineracao/command',
    name: 'Command',
    component: Command
  },
  {
    path: '/mineracao/health',
    name: 'Health',
    component: Health
  },
  {
    path: '/mineracao/fleet',
    name: 'Fleet',
    component: Fleet
  },
  {
    path: '/mineracao/fadiga',
    name: 'Fadiga',
    component: Fadiga
  },
  {
    path: '/mineracao/detect',
    name: 'Detect',
    component: Detect
  },
  {
    path: '/mineracao/terrain',
    name: 'Terrain',
    component: Terrain
  },
  {
    path: '/mineracao/aplicacoes/carregamento-transporte',
    name: 'CarregamentoTransporte',
    component: CarregamentoTransporte
  },
  {
    path: '/mineracao/aplicacoes/infraestrutura-suporte',
    name: 'InfraestruturaSuporte',
    component: InfraestruturaSuporte
  },
  {
    path: '/mineracao/aplicacoes/perfuracao',
    name: 'Perfuracao',
    component: Perfuracao
  },
  {
    path: '/mineracao/aplicacoes/projetos-especiais',
    name: 'ProjetosEspeciais',
    component: ProjetosEspeciais
  },
  {
    path: '/mineracao/aplicacoes/subterranea',
    name: 'Subterranea',
    component: Subterranea
  },
  {
    path: '/mineracao/solucoes',
    name: 'SolucoesMineracao',
    component: SolucoesMineracao
  },
  {
    path: '/mineracao/solucoes/autonomas',
    name: 'Autonomas',
    component: Autonomas
  }, {
    path: '/mineracao/solucoes/eficiencia-operacional',
    name: 'EficienciaOperacional',
    component: EficienciaOperacional
  }, {
    path: '/mineracao/solucoes/monitoramento-condicoes',
    name: 'MonitoramentoCondicoes',
    component: MonitoramentoCondicoes
  }, {
    path: '/mineracao/solucoes/operacoes-remotas',
    name: 'OperacoesRemotas',
    component: OperacoesRemotas
  }, {
    path: '/mineracao/solucoes/seguranca',
    name: 'Seguranca',
    component: Seguranca
  },
  {
    path: '/mineracao/solucoes/sustentabilidade-mineracao',
    name: 'SustentabilidadeMineracao',
    component: SustentabilidadeMineracao
  },
  {
    path: '/nossasmaquinas/catg/:id',
    name: 'CategoriaEspecific',
    component: CategoriaEspecific
  },
  {
    path: '/nossasmaquinas/subcatg/:id',
    name: 'SubCategoriaEspecific',
    component: SubCategoriaEspecific
  },
  {
    path: '/nossasmaquinas/maquina/:id',
    name: 'MaquinaDetalhe',
    component: MaquinaDetalhe
  },
  {
    path: '/acessorios/:id',
    name: 'AcessorioDetalhe',
    component: AcessorioDetalhe
  },
  {
    path: '/filiais',
    name: 'Filiais',
    component: Filiais
  },
  {
    path: '/filial/:id',
    name: 'FilialDetalhe',
    component: FilialDetalhe
  },
  {
    path: '/lab-sos',
    name: 'LaboratorioSOS',
    component: LaboratorioSOS
  },
  {
    path: '/lab-sos/como-realizar-amostra',
    name: 'Amostra1234',
    component: Amostra1234
  },
  {
    path: '/lab-sos/oleo-diesel',
    name: 'OleoDisel',
    component: OleoDisel
  },
  {
    path: '/lab-sos/kits-montagem',
    name: 'KitsMontagem',
    component: KitsMontagem
  },
  {
    path: '/lab-sos/fichas-manuais',
    name: 'FichasManuais',
    component: FichasManuais
  },
  {
    path: '/lab-sos/colete-amostra',
    name: 'ColeteAmostra',
    component: ColeteAmostra
  },
  {
    path: '/lab-sos/liquido-lubrificante',
    name: 'OleoLubrificante',
    component: OleoLubrificante
  },
  {
    path: '/lab-sos/liquido-arrefecimento',
    name: 'Arrefecimento',
    component: Arrefecimento
  },
  {
    path: '/lab-sos/nosso-lab',
    name: 'NossoLab',
    component: NossoLab
  },
  {
    path: '/sobre-nos',
    name: 'Institucional',
    component: Institucional
  },
  {  
    path: '/sobre-nos/esg',
    name: 'Esg',
    component: Esg
  },
  {
    path: '/sobre-nos/politica',
    name: 'PoliticaSust',
    component: PoliticaSust
  },
  {
    path: '/sobre-nos/valor-corporativo',
    name: 'ValorCorporativo',
    component: ValorCorporativo
  },
  {
    path: '/sobre-nos/premios-certificacoes',
    name: 'PremiosCertific',
    component: PremiosCertific
  },
  {
    path: '/sobre-nos/wallpapers',
    name: 'Wallpapers',
    component: Wallpapers
  },
  {
    path: '/sobre-nos/imprensa',
    name: 'Imprensa',
    component: Imprensa
  },
  {
    path: '/sobre-nos/isso',
    name: 'iSSO',
    component: iSSO
  },
  {
    path: '/sobre-nos/sistema-de-gestao-integrado',
    name: 'Sustentabilidade',
    component: Sustentabilidade
  },
  {
    path: '/sobre-nos/anti-corrupcao',
    name: 'AntiCorrupcao',
    component: AntiCorrupcao
  },
  {
    path: '/sobre-nos/conduta',
    name: 'Conduta',
    component: Conduta
  },
  {
    path: '/financiamento',
    name: 'Financiamento',
    component: Financiamento
  },
  {
    path: '/financiamento/finame',
    name: 'FINAME',
    component: FINAME
  },
  {
    path: '/financiamento/consorcios',
    name: 'Consorcios',
    component: Consorcios
  },
  {
    path: '/financiamento/cat-financial',
    name: 'CATFinancial',
    component: CATFinancial
  },
  {
    path: '/aplicacoes',
    name: 'Aplicacoes',
    component: Aplicacoes
  },
  {
    path: '/aplicacoes/sotreq-link',
    name: 'SotreqLinkTec',
    component: SotreqLinkTec
  },
  {
    path: '/aplicacoes/sitech',
    name: 'Sitech',
    component: Sitech
  },
  {
    path: '/aplicacoes/cat-inspect',
    name: 'CATinspect',
    component: CATinspect
  },
  {
    path: '/aplicacoes/vision-link',
    name: 'VisionLink',
    component: VisionLink
  },
  {
    path: '/aplicacoes/sos-web',
    name: 'SOSWeb',
    component: SOSWeb
  },
  {
    path: '/aplicacoes/cat-connect',
    name: 'CATConnect',
    component: CATConnect
  },
  {
    path: '/aplicacoes/rajant',
    name: ' Rajant',
    component: Rajant
  },
  {
    path: '/aplicacoes/drenagem-eficiente',
    name: ' DrenagemEficiente',
    component: DrenagemEficiente
  },

  {
    path: '/aplicacoes/product-link-web',
    name: 'ProductLinkWeb',
    component: ProductLinkWeb
  },
  {
    path: '/aplicacoes/solucoes-maritimo',
    name: 'SolucoesMaritimo',
    component: SolucoesMaritimo
  },
  {
    path: '/servicos/energia',
    name: 'ServicosEnergia',
    component: ServicosEnergia
  },
  {
    path: '/servicos/maritimo-lazer',
    name: 'ServicoMaritimoLazer',
    component: ServicoMaritimoLazer
  },
  {
    path: '/servicos/treinamentos-tecnicos',
    name: 'ServicoTreinamentosTecnicos',
    component: ServicoTreinamentosTecnicos
  },

  {
    path: '/servicos/treinamentos-presenciais',
    name: 'TreinamentosPresenciais',
    component: TreinamentosPresenciais
  },
  {
    path: '/servicos/treinamentos-presenciais/treinamento/:id',
    name: 'TreinamentoInfo',
    component: TreinamentoInfo
  },
  {
    path: '/servicos/treinamentos-presenciais/cat/:id',
    name: 'TreinamentosPresenciaisInnerCategoria',
    component: TreinamentosPresenciaisInnerCategoria
  },
  {
    path: '/servicos/maritimo-comercial',
    name: 'ServicoMaritimoComercial',
    component: ServicoMaritimoComercial
  },
  {
    path: '/servicos/oleo-e-gas',
    name: 'ServicoOleoEGas',
    component: ServicoOleoEGas
  },
  {
    path: '/servicos/crc',
    name: 'ServicoCRC',
    component: ServicoCRC
  },
  {
    path: '/programa-sos',
    name: 'ProgramaSOS',
    component: ProgramaSOS
  },
  {
    path: '/areiaebrita',
    name: 'AreiaeBrita',
    component: AreiaEBrita
  },
  {
    path: '/contratos',
    name: 'Contratos',
    component: Contratos
  },
  {
    path: '/contratos/sotreq-link',
    name: 'SotreqLink',
    component: SotreqLink
  },
  {
    path: '/contratos/sotreq-link/lite',
    name: 'SotreqLinkLite',
    component: SotreqLinkLite
  },
  {
    path: '/contratos/sotreq-link/classic',
    name: 'SotreqLinkClassic',
    component: SotreqLinkClassic
  },
  {
    path: '/contratos/sotreq-link/plus',
    name: 'SotreqLinkPlus',
    component: SotreqLinkPlus
  },
  {
    path: '/contratos/construcao',
    name: 'ConstrucaoContrato',
    component: ConstrucaoContrato
  },
  {
    path: '/contratos/petroleo-gas',
    name: 'PetroleoGasContrato',
    component: PetroleoGasContrato
  },
  {
    path: '/contratos/petroleo-gas/pmp',
    name: 'PmpContrato',
    component: PmpContrato
  },
  {
    path: '/contratos/petroleo-gas/maqextra',
    name: 'MaqExtra',
    component: MaqExtra
  },
  {
    path: '/contratos/petroleo-gas/service-plus-marc',
    name: 'ServicePlusMarc',
    component: ServicePlusMarc
  },
  {
    path: '/contratos/mineracao',
    name: 'MineracaoContrato',
    component: MineracaoContrato
  },
  {
    path: '/contratos/mineracao/CVA',
    name: 'CVA',
    component: CVA
  },
  {
    path: '/contratos/mineracao/MARC',
    name: 'MARC',
    component: MARC
  },
  {
    path: '/contratos/mineracao/PBTH',
    name: 'PBTH',
    component: PBTH
  },
  {
    path: '/contratos/mineracao/PBTW',
    name: 'PBTW',
    component: PBTW
  },
  {
    path: '/pecas-genuinas-cat-r',
    name: 'PecasGenuinasCatR',
    component: PecasGenuinasCatR
  },
  {
    path: '/servicos-manutencao',
    name: 'ServicosManutencao',
    component: ServicosManutencao
  },
  {
    path: '/pecas-usadas',
    name: 'PecasUsadas',
    component: PecasUsadas
  },
  {
    path: '/opcoes-de-reparo',
    name: 'OpcoesReparo',
    component: OpcoesReparo
  },
  {
    path: '/pecas-genuinas-cat-r/baterias',
    name: 'Baterias',
    component: Baterias
  },
  {
    path: '/pecas-genuinas-cat-r/filtros',
    name: 'Filtros',
    component: Filtros
  },
  {
    path: '/pecas-genuinas-cat-r/fluidos',
    name: 'Fluidos',
    component: Fluidos
  },
  {
    path: '/pecas-genuinas-cat-r/penetracao-de-solo',
    name: 'PenetracaoSolo',
    component: PenetracaoSolo
  },
  {
    path: '/pecas-genuinas-cat-r/hidraulicos',
    name: 'Hidraulicos',
    component: Hidraulicos
  },
  {
    path: '/pecas-genuinas-cat-r/hidraulicos',
    name: 'Hidraulicos',
    component: Hidraulicos
  },
  {
    path: '/pecas-genuinas-cat-r/material-rolante',
    name: 'MaterialRodante',
    component: MaterialRodante
  },
  {
    path: '/pecas-genuinas-cat-r/mangueiras',
    name: 'Mangueiras',
    component: Mangueiras
  },
  {
    path: '/pecas-genuinas-cat-r/pecas-reman',
    name: 'PecasReman',
    component: PecasReman
  },
  {
    path: '/sistema-propulsao',
    name: 'SistemaDePropulsao',
    component: SistemaDePropulsao
  },
  {
    path: '/acessorios',
    name: 'acessorios',
    component: Acessorios
  },
  {
    path: '/nossasmaquinas',
    name: 'NossasMaquinas',
    component: NossasMaquinas
  },
  {
    path: '/oleoegas',
    name: 'OleoEGas',
    component: OleoEGas
  },
  {
    path: '/transmissoes',
    name: 'Transmissoes',
    component: Transmissoes
  },
  {
    path: '/produtos/bombas-de-servico-de-poco',
    name: 'Bombaspoco',
    component: Bombaspoco
  },
  {
    path: '/produtos/sem',
    name: 'Sem',
    component: Sem
  },
  {
    path: '/produtos/mangueiras',
    name: 'ProdutosMangueiras',
    component: ProdutosMangueiras
  },
  {
    path: '/filtragem',
    name: 'Filtragem',
    component: Filtragem
  },
  {
    path: '/acessorio-inner/:str',
    name: 'AcessorioInner',
    component: AcessorioInner
  },
  {
    path: '/categoria-acessorios/:id',
    name: 'SubcategoriaAcessorios',
    component: SubcategoriaAcessorios
  },
  {
    path: '/grupo-geradores-e-motores',
    name: 'GrupoGeradoreseMotores',
    component: GrupoGeradoreseMotores
  },
  {
    path: '/motores',
    name: 'motores',
    component: Motores
  },
  {
    path: '/motores/maritimo',
    name: 'MotoresMaritimos',
    component: MotoresMaritimos
  },
  {
    path: '/motores/industrial',
    name: 'MotoresIndustriais',
    component: MotoresIndustriais
  },
  {
    path: '/grupo-geradores',
    name: 'grupo-geradores',
    component: GrupoGeradores
  },
  {
    path: '/pavimentacao',
    name: 'Pavimentacao',
    component: Pavimentacao
  },
  {
    path: '/aluguel',
    name: 'Aluguel',
    component: Aluguel
  },
  {
    path: '/maritimo',
    name: 'Maritimo',
    component: Maritimo
  },
  {
    path: '/energia',
    name: 'Energia',
    component: Energia
  },
  {
    path: '/industrial',
    name: 'Industrial',
    component: Industrial
  },
  {
    path: '/construcao',
    name: 'Construcao',
    component: Construcao
  },
  {
    path: '/agricola',
    name: 'Agricola',
    component: Agricola
  },
  {
    path: '/florestal',
    name: 'Florestal',
    component: Florestal
  },
  {
    path: '/mineracao',
    name: 'Mineracao',
    component: Mineracao
  },
  {
    path: '/promocoes',
    name: 'Promocoes',
    component: Promocoes
  },
  {
    path: '/cat-advantage',
    name: 'CatAdvantage',
    component: CatAdvantage
  },
  {
    path: '/faleconosco',
    name: 'Faleconosco',
    component: FaleConosco
  },
  {
    path: '/oldcontato',
    name: 'Contato',
    component: Contato
  },
  {
    path: '/contato',
    name: 'FaleContato',
    component: FaleContato
  },
  {
    path: '/formularioenviado',
    name: 'FormularioEnviado',
    component: FormularioEnviado
  },
  {
    path: '/newsletterenviado',
    name: 'NewsletterEnviado',
    component: NewsletterEnviado
  },
  {
    path: '/filtragem/industrial',
    name: 'FiltragemIndustrial',
    component: FiltragemIndustrial
  },
  {
    path: '/filtragem/maritimo',
    name: 'FiltragemMaritimo',
    component: FiltragemMaritimo
  },
  {
    path: '/filtragem/oleoegas',
    name: 'FiltragemOleoegas',
    component: FiltragemOleoegas
  },
  {
    path: '/filtragem/mineracao',
    name: 'FiltragemMineracao',
    component: FiltragemMineracao
  },
  {
    path: '/filtragem/energia',
    name: 'FiltragemEnergia',
    component: FiltragemEnergia
  },
  {
    path: '/filtragem/eolico',
    name: 'FiltragemEolico',
    component: FiltragemEolico
  },
  {
    path: '/pecas/pneus',
    name: 'PecasPneus',
    component: PecasPneus
  },
  {
    path: '/carreiras',
    name: 'Carreiras',
    component: Carreiras
  },
  {
    path: '/carreiras-trab',
    name: 'CarreirasTrab',
    component: TrabalheConosco
  },
  {
    path: '/pecas/cjc',
    name: 'Cjc',
    component: Cjc
  },
  {
    path: '/pecas/emd',
    name: 'Emd',
    component: Emd
  },
  {
    path: '/pecas/mak',
    name: 'Mak',
    component: Mak
  },
  {
    path: '/pecas/spbt',
    name: 'Spbt',
    component: Spbt
  },
  {
    path: '/servicos/oficina',
    name: 'ServicosOficina',
    component: ServicosOficina
  },
  {
    path: '/servicos/campo',
    name: 'ServicosCampo',
    component: ServicosCampo
  },
  {
    path: '/material/rodante',
    name: 'Rodante',
    component: Rodante
  },
  {
    path: '/servicos/hidraulico',
    name: 'ServicosHidraulico',
    component: ServicosHidraulico
  },
  {
    path: '/contratos/petroleo-gas/maqextra/trem-forca',
    name: 'TremDeForca',
    component: TremDeForca
  },
  {
    path: '/contratos/petroleo-gas/maqextra/tfsh',
    name: 'Tfsh',
    component: Tfsh
  },
  {
    path: '/contratos/petroleo-gas/maqextra/premier',
    name: 'Premier',
    component: Premier
  },
  {
    path: '/cat360advantage',
    name: 'Cat360Advantage',
    component: Cat360Advantage
  },
  {
    path: '/consumo-de-combustivel',
    name: 'ConsumoDeCombustivel',
    component: ConsumoDeCombustivel
  },
  {
    path: '/politica-privacidade',
    name: 'Politica',
    component: Politica
  },
  {
    path: '/demonstracoes_contabeis',
    name: 'Contabeis',
    component: Contabeis
  },
   {
    path: '/igualdade-salarial',
    name: 'IgualdadeSalarial',
    component: IgualdadeSalarial
  },
  {
    path: '/retiraqui',
    name: 'Locker',
    component: Locker
  },
  {
    path: '/black-friday-sotreq1',
    name: 'Blackfriday1',
    component: Blackfriday1
  },
  {
    path: '/black-friday-sotreq2',
    name: 'Blackfriday2',
    component: Blackfriday2
  },
  {
    path: '/black-friday-sotreq',
    name: 'Blackfriday3',
    component: Blackfriday3
  },
  {
    path: '/formularioenviado-blackfriday',
    name: 'FormularioEnviadoBlackfriday',
    component: FormularioEnviadoBlackfriday
  },
  {
    path: '/pecas-usadas-formulario-enviado',
    name: 'FormularioPecasUsadasEnviado',
    component: FormularioPecasUsadasEnviado
  },
//  {
//    path: '/nossasmaquinas*',
//    beforeEnter (to, from, next) {
//      window.location.href = 'https://www.cat.com/pt_BR/products/new/equipment.html'
 //   }
//  },
  {
    path: '/produtos/cilindros',
    name: 'Cilindros',
    component: Cilindros
  },
  {
    path: '/produtos/bombas-e-motores',
    name: 'BombasMotores',
    component: BombasMotores
  },
  {
    path: '/:id-formulario-enviado',
    name: 'FormularioGeralEnviado',
    component: FormularioGeralEnviado
  },
    {
  	path: '/projetos-incentivados',
  	name: 'ProjetosIncentivados',
  	component: ProjetosIncentivados
    },
    {
  	path: '/teste-chat',
  	name: 'TesteChat',
  	component: TesteChat
    },
  {
    path: '/tecnico-autorizado',
    name: 'TecnicoAutorizado',
    component: TecnicoAutorizado
  },
  {
    path: '/garantia',
    name: 'Garantia',
    component: Garantia
  },
  {
    path: '/depoimentos',
    name: 'Depoimentos',
    component: Depoimentos
  },
  {
    path: '/pecas-online',
    name: 'PecasOnline',
    component: PecasOnline
  },
  {
    path: '/tele-engenharia',
    name: 'TeleEngenharia',
    component: TeleEngenharia
  }, 
  {
    path: '/tecnologia/solucoes-mineracao',
    name: 'TecnologiaSolucoesMineracao',
    component: TecnologiaSolucoesMineracao
  },
  {
    path: '/tecnologia/solucoes-sotreqlink',
    name: 'TecnologiaSolucoesSotreqlink',
    component: TecnologiaSolucoesSotreqlink
  },
  {
    path: '/financiamento-finame',
    name: 'FinanciamentoFiname',
    component: FinanciamentoFiname
  },
  {
    path: '/tecnologia/mineracao-subterranea',
    name: 'MineracaoSubterranea',
    component: MineracaoSubterranea
  },
  {
    path: '/tecnologia/sotreq-cat',
    name: 'SotreqCat',
    component: SotreqCat
  },
  {
    path: '/tecnologia/sotreq-resemin',
    name: 'SotreqResemin',
    component: SotreqResemin
  },
  {
    path: '/tecnologia/sotreq-robit',
    name: 'SotreqRobit',
    component: SotreqRobit
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/About.vue')
  }
]

const router = new VueRouter({
  routes
})

Vue.use(VueGtm, {
  id: 'GTM-KPPH228', // Your GTM single container ID or array of container ids ['GTM-xxxxxx', 'GTM-yyyyyy'] or array of objects [{id: 'GTM-xxxxxx', queryPararms: { gtm_auth: 'abc123', gtm_preview: 'env-4', gtm_cookies_win: 'x'}}, {id: 'GTM-yyyyyy', queryParams: {gtm_auth: 'abc234', gtm_preview: 'env-5', gtm_cookies_win: 'x'}}]
  defer: false, // defaults to false. Script can be set to `defer` to increase page-load-time at the cost of less accurate results (in case visitor leaves before script is loaded, which is unlikely but possible)
  enabled: true, // defaults to true. Plugin can be disabled by setting this to false for Ex: enabled: !!GDPR_Cookie (optional)
  debug: true, // Whether or not display console logs debugs (optional)
  loadScript: false, // Whether or not to load the GTM Script (Helpful if you are including GTM manually, but need the dataLayer functionality in your components) (optional)
  vueRouter: router // Pass the router instance to automatically sync with router (optional)
})

export default router
