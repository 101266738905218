<template>
  <div>
    <p class="lineheight">
      <a class="notalink" href="https://isso.org.br">iSSO</a>
    </p>
    <p class="lineheight">
      <a class="notalink" href="https://mdpower.com.br">MD Power</a>
    </p>
    <p class="lineheight">
      <a class="notalink" href="https://radix.com.br">Radix</a>
    </p>
   <p class="lineheight">
      <a class="notalink" href="http://sitechbr.com.br">SITECH</a>
    </p>
    <p class="lineheight">
      <a class="notalink" href="https://soimpex.com.br/">Soimpex</a>
    </p>
    <p class="lineheight">
      <a class="notalink" href="https://somov.com.br">Somov</a>
    </p>
    <p class="lineheight">
      <a class="notalink" href="https://hub.sotreq.com.br/sotreqagro#central">Sotreq Agro</a>
    </p>
    <p class="lineheight">
      <a class="notalink" href="https://sotreq.com.br/#/aluguel">Sotreq Rental</a>
    </p>
    <p class="lineheight">
      <a class="notalink" href="https://sotreqseminovos.com.br">Sotreq Seminovos</a>
    </p>
    <p class="lineheight mb-0">
      <a class="notalink" href="https://sotreq.com.br/#/florestal">Sotreq Florestal</a>
    </p>
  </div>
</template>
<script type="text/javascript">
export default {
  name: 'FooterEmpresas'
}
</script>
<style scoped>
</style>
